.root {
    position: relative;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    gap: 1rem;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    filter: drop-shadow(0 1px 4px rgba(40, 40, 40, 0.2));
    max-width: 440px;
    position: relative;
    z-index: 1;
    align-self: center;
    margin: 1rem;
}

.frame {
    display: flex;
    flex-direction: column;
    position: relative;
}

.title {
    position: relative;
    z-index: 2;
    font-size: 3rem;
    font-weight: bold;
}

.subtitle {
    position: relative;
    z-index: 2;
    font-size: 2rem;
    font-weight: normal;
}

.topLine {
    position: absolute;
    height: 1rem;
    background-color: var(--secondary-color);
    width: calc(100% + 4rem);
    right: -3rem;
    top: -2rem;
    border-radius: 1rem;
}

.leftLine {
    position: absolute;
    width: 1rem;
    background-color: var(--secondary-color);
    height: calc(100% + 8rem);
    left: -3rem;
    top: -6rem;
    border-radius: 1rem;
}

.rightLine {
    position: absolute;
    width: 1rem;
    background-color: var(--secondary-color);
    height: calc(100% + 8rem);
    right: -3rem;
    bottom: -6rem;
    border-radius: 1rem;
}

.bottomLine {
    position: absolute;
    height: 1rem;
    background-color: var(--secondary-color);
    width: calc(100% + 4rem);
    left: -3rem;
    bottom: -2rem;
    border-radius: 1rem;
}

.bottomNotch {
    position: absolute;
    height: 1rem;
    background-color: var(--secondary-color);
    width: 3rem;
    right: -7rem;
    bottom: -2rem;
    border-radius: 1rem;
}

.topNotch {
    position: absolute;
    height: 1rem;
    background-color: var(--secondary-color);
    width: 3rem;
    left: -7rem;
    top: -2rem;
    border-radius: 1rem;
}
/*!* Hide frame when screen is too small *!*/
/*@media (max-width: 770px) {*/
/*    .frame {*/
/*        display: none;*/
/*    }*/
/*}*/

/*@media (max-height: 680px) {*/
/*    .frame {*/
/*        display: none;*/
/*    }*/
/*}*/
