.button {
    background-color: var(--primary-color);
    padding: 0.5rem 1rem;
    cursor: pointer;
    width: max-content;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    border-radius: 0.5rem;
    user-select: none;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.button.disabled {
    pointer-events: none;
    filter: grayscale(1);
    opacity: 0.7;
}

.button.secondary {
    background-color: white;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}
