.root {
    height: 100%;
    background-color: var(--background-color);
}

.frame {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.config {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: flex-start;
    align-items: center;
    padding-left: 1rem;
    gap: 1rem;
}
