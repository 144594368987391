.root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    gap: 2rem;
    padding: 1.5rem;
}
